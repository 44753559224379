import React, { useEffect, useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import Tilt from "react-tilt";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

const HomeThree = () => {
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/home3-slider.png)`,
  });
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const color = localStorage.getItem("color");

  useEffect(() => {

    const handleResize = () => {
      setScreenSize(window.innerWidth);
      setIsSmallScreen(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (color === "color-1") {
      if (screenSize < 768) {
        setBgImg({
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/BG_MOBILE.png)`,
        });
      } else {
        setBgImg({
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/home3-slider.png)`,
        });
      }
    } else if (color === "color-2") {
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/Homev3.png)`,
      });
    } else {
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev3.png)`,
      });
    }
  }, [color, screenSize]);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style-two");
  document.body.classList.add("home-style");
  document.body.classList.add("three");

  return (
    <div>
      <Navbar />
      <section id="home" className="home home-three vertical-scrolling" style={{...bgImg, position: 'relative'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="home-contain">
                <div className="text-white">
                  <div className="contain">
                    <img
                        id='title_first'
                        src={`${process.env.PUBLIC_URL}/assets/images/MF_Title_left.png`}
                        alt="Magic Friends"
                        style={{ height: '60px' }}
                        className="shimmer"
                    />
                    <img
                      id='title_second'
                      src={`${process.env.PUBLIC_URL}/assets/images/Create_Learn_Play.png`}
                      alt="Magic Friends"
                      style={{ height: '45px', marginTop: '25px' }}
                      className="floating-image"
                    />             
                    <img
                      id="title_third"
                      src={isSmallScreen
                        ? `${process.env.PUBLIC_URL}/assets/images/white_text_mobile.png`
                        : `${process.env.PUBLIC_URL}/assets/images/white_text.png`
                      }
                      alt="Magic Friends"
                      style={{
                        height: isSmallScreen ? '30px' : '40px',
                        marginTop: isSmallScreen ? '5px' : '15px',
                      }}
                      className="floating-image"
                   />        
                  </div>
                  <div className="play-button">
                    <Subscribe />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="home-right">
                <Tilt options={{ perspective: 110, speed: 400, max: 1.2, scale: 1 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/home3/home-3-img.png`}
                    className="img-fluid wobble-image"
                    alt="slider-caption"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
        
        {/* Centered Title Image */}
        <div id= 'main_title_container'
          style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none'
        }}>
          <img 
            id='main_title'
            src={`${process.env.PUBLIC_URL}/assets/images/Title_1.png`}
            alt="Magic Friends"
            style={{ height: '90px' }}
            className="pulse-glow-effect"
          />     
        </div>
      </section>
  
      <Footer />
    </div>
  );
};

export default HomeThree;